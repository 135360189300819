<template>
  <WsClassIndex
    :target="_modelName"
    :label="`${$store.state.stone_model[_modelName].label}`"
    v-bind="$store.state.stone_model[_modelName].crudSetting.ws_class"
  />
</template>

<script>
export default {
  computed: {
    _modelName() {
      return this.$route.meta.modelName;
    },
  },
};
</script>